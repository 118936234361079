export const isEmptyObject = (obj) => {
  // check that the object has no properties
  if (obj === undefined || obj === null) return true;
  return Object.getOwnPropertyNames(obj).length < 2;
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const generateGuideTrackerUrl = (guideId) => {
  return `https://${window.location.host}/rastreo?guia=${guideId}`;
};
export const generateQr = (guideId) => {
  //
  const urlToGuide = `https://aerocontrol.herokuapp.com/update/${guideId}`;
  return `https://api.qrserver.com/v1/create-qr-code/?size=220x220&data=${urlToGuide}`;
};

export const generateBarcode = (guideId) => {
  let params = {
    data: guideId,
    code: "EANUCC128",
    multiplebarcodes: "false",
    "translate-esc": "true",
    unit: "Fit",
    dpi: "300",
    imagetype: "PNG",
    rotation: 0,
    color: "%23000000",
    bgcolor: "%23ffffff",
    codepage: "Default",
    qunit: "Mm",
    quiet: "0",
    hidehrt: "False",
  };
  params = Object.entries(params)
    .map((el) => el.join("="))
    .join("&");
  return `https://barcode.tec-it.com/barcode.ashx?${params}`;
};
export const getAccessKeyValue = (name) =>
  ACCESS_KEYS[name] ? ACCESS_KEYS[name] : null;

const ACCESS_KEYS = {
  ALF: "ALF",
  RFX: "Redfox",
  FAA: "Flecha Amarilla",
  "99M": "99 Minutos",
  BJP: "Baja Pack",
  MEX: "Cedis CDMX",
  MTY: "Cedis Monterrey",
  GDL: "Cedis Guadalajara",
  TIJ: "Cedis Tijuana",
  CUN: "Cedis Cancún",
  MED: "Cedis Mérida",
  SJD: "Cedis San José del Cabo",
  SJS: "Cedis Cd Juárez Chihuahua",
  CUL: "Cedis Culiacán",
  QRO: "Cedis Querétaro",
  OAX: "Cedis Oaxaca",
  VIV: "Conexión Viva Aerobus",
};

export const MexicanStates = [
  "Aguascalientes",
  "Baja California Norte",
  "Baja California Sur",
  "Campeche",
  "Chiapas",
  "Chihuahua",
  "Ciudad de México",
  "Estado de México",
  "Ciudad de Mexico",
  "Estado de Mexico",
  "Coahuila",
  "Colima",
  "Durango",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "México",
  "Michoacán",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Nuevo Leon",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Queretaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz",
  "Yucatan",
  "Yucatán",
  "Zacatecas",
];

export const formasPago = [
  "01 - Efectivo",
  "02 - Cheque nominativo",
  "03 - Transferencia electrónica de fondos",
  "04 - Tarjeta de Crédito",
  "05 - Monedero electrónico",
  "06 - Dinero electrónico",
  "08 - Vales de despensa",
  "12 - Dación de pago",
  "13 - Pago por subrogacion",
  "14 - Pago por consignación",
  "15 - Condonación",
  "17 - Compensación",
  "23 - Novación",
  "24 - Confusión",
  "25 - Remisión de deuda",
  "26 - Prescripción o caducidad",
  "27 - A satisfacción del acreedor",
  "28 - Tarjeta de débito",
  "29 - Tarjeta de servicios",
  "99 - Por definir",
];

export const cfdi = [
  "G01 - Adquisición de mercancias",
  "G03 - Gastos en general",
  "I01 - Construcciones",
  "I02 - Mobiliario y equipo de oficina por inversiones",
  "I03 - Equipo de transporte",
  "I04 - Equipo de computo y accesorios",
  "I05 - Dados, troquetes, moldes, matrices y herramental",
  "I06 - Comunicaciones telefónicas",
  "I07 - Comunicaciones satelitales",
  "I08 - Otra maquinaria y equipo",
  "D01 - Honorarios médicos, dentales y gastos hospitalarios",
  "D02 - Gastos médicos por incapacidad o discapacidad",
  "D03 - Gastos funerales",
  "D04 - Donativos",
  "D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
  "D06 - Aportaciones voluntarias al SAR",
  "D07 - Primas por seguros de gastos médicos",
  "D08 - Gastos de transportación escolar obligatoria",
  "D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
  "D10 - Pagos por servicios educativos (colegiaturas)",
  "S01 - Sin efectos fiscales",
  "CP01 - Pagos",
  "CN01 - Nómina",
];

export const regimenFiscal = [
  "601 - General de Ley Personas Morales",
  "603 - Personas Morales con Fines no Lucrativos",
  "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios",
  "606 - Arrendamiento",
  "607 - Régimen de Enajenación o Adquisición de Bienes",
  "608 - Demás ingresos",
  "610 - Residentes en el Extranjero sin Establecimiento Permanente en México",
  "611 - Ingresos por Dividendos (socios y accionistas)",
  "612 - Personas Físicas con Actividades Empresariales y Profesionales",
  "614 - Ingresos por intereses",
  "615 - Régimen de los ingresos por obtención de premios",
  "616 - Sin obligaciones fiscales",
  "620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
  "621 - Incorporación Fiscal",
  "622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  "623 - Opcional para Grupos de Sociedades",
  "624 - Coordinados",
  "625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  "626 - Régimen Simplificado de Confianza",
];

export const cfdiRegimen = [
  {
    G01: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
    G02: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
    G03: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
    I01: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
    I02: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
    I03: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
    I04: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
    I05: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
    I06: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
    I07: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
    I08: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
    D01: [605, 606, 607, 608, 611, 612, 614, 615, 625],
    D02: [605, 606, 607, 608, 611, 612, 614, 615, 625],
    D03: [605, 606, 607, 608, 611, 612, 614, 615, 625],
    D04: [605, 606, 607, 608, 611, 612, 614, 615, 625],
    D05: [605, 606, 607, 608, 611, 612, 614, 615, 625],
    D06: [605, 606, 607, 608, 611, 612, 614, 615, 625],
    D07: [605, 606, 607, 608, 611, 612, 614, 615, 625],
    D08: [605, 606, 607, 608, 611, 612, 614, 615, 625],
    D09: [605, 606, 607, 608, 611, 612, 614, 615, 625],
    D10: [605, 606, 607, 608, 611, 612, 614, 615, 625],
    S01: [
      601, 603, 605, 606, 607, 608, 610, 611, 612, 614, 615, 616, 620, 621, 622,
      623, 624, 625, 626,
    ],
    CP01: [
      601, 603, 605, 606, 607, 608, 610, 611, 612, 614, 615, 616, 620, 621, 622,
      623, 624, 625, 626,
    ],
    CN01: [605],
  },
];

const airportAddress = {
  MTY: {
    street: "Boulevard Aeropuerto",
    exterior: "12",
    interior: "10",
    city: "Apodaca",
    postalcode: "66600",
    state: "Nuevo León",
    reference_street: "",
    colony: "n/a",
    municipality: "Apodaca",
  },
  GDL: {
    street: "Carretera Guadalajara-Chapala",
    exterior: "33",
    interior: "18",
    city: "n/a",
    postalcode: "00000",
    state: "Jalisco",
    reference_street: "Plaza Hangar",
    colony: "n/a",
    municipality: "n/a",
  },
  MEX: {
    street: "Francisco Sarabia No. 17, Col. Peñón de los baños, C.P. 15620.",
    exterior: "17",
    interior: "",
    city: "n/a",
    postalcode: "00000",
    state: "México",
    reference_street: "",
    colony: "Peñón de los baños",
    municipality: "n/a",
  },
  CUN: {},
  QRO: {},
  MID: {},
  OAX: {},
  SJD: {},
  CUL: {},
  CJS: {},
  CUU: {},
  LAP: {},
  TIJ_RECEPCION: {},
  TIJ_ENTREGA: {},
};

export const parseStrings = (number) => {
  return parseFloat(number).toFixed(2);
};

export const setStateVisitPage = (state) => {
  sessionStorage.setItem("visit", state);
};

export const getVisitPage = () => {
  const visit = JSON.parse(sessionStorage.getItem("visit"));
  return visit;
};

export const hasFieldsErrors = (errors) => {
  if (!errors?.length) return false;
  return errors.join("\n");
};
