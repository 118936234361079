import { BACKEND_URL, USER, PASS } from "../../config.json"
import { API_FACTURACION } from "../constants"
import { cfdiRegimen } from "../../components/helpers"

const URL_SENDINBLUE_PIPEDREAM = "https://enytl2sehbbmk9q.m.pipedream.net"
const API_LOGIN = "https://front.awesome-cargo.com/api/login"
const SEARCH_TK = "https://front.awesome-cargo.com/api/cpostales?search="
// * Validar toda la direccion
export const loginFacturacion = async () => {
  console.log("LOGIN")
  try {
    const response = await fetch(API_LOGIN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: USER,
        password: PASS
      }),
    })
    const { message, data } = await response.json()
    if (message === 'Login Successful') {
      const { access_token } = data
      sessionStorage.setItem("access_token", access_token)
      return {
        response: true,
        message: "Login AUTH"
      }
    }
  } catch (err) {
    return {
      response: false,
      message: "Ocurrió un error en el servidor, intente más tarde."
    }
  }
}

export const validateCP = async ({ dataFacturacion }) => {
  // console.log(dataFacturacion)
  // console.log("zzz")
  // const { colony, municipality } = dataFacturacion
  // console.log(colony, municipality)
  const CODIGO_POSTAL = dataFacturacion?.postalcode
  console.log(parseFloat(CODIGO_POSTAL))
  const colonia = dataFacturacion?.colony
  const municipio = dataFacturacion?.municipality
  const estado = dataFacturacion?.estado
  console.log(estado)
  const validateAddress = []
  const dataAddress = []
  try {
    const TOKEN = sessionStorage.getItem('access_token')
    const response = await fetch(`${SEARCH_TK}${CODIGO_POSTAL}`, {
      method: 'GET',
      headers: {
        'Authorization': TOKEN,
        "Content-Type": "application/json",
      }
    })
    const json = await response.json()
    const { data } = json
    if (data?.current_page >= 1) {
      // console.log(data)
      // * Validar CP
      const matchCP = data?.data.filter(item => item.CODIGO_POSTAL === CODIGO_POSTAL)
      // * Validar Colonia
      const matchColonia = data?.data.filter(item => item.COLONIA === removeAccents(colonia.toUpperCase()))
      // * Validar Municipio
      const matchMunicipio = data?.data.filter(item => item.MUNICIPIO === removeAccents(municipio.toUpperCase()))
      // * Validar Estado
      const matchEstado = data?.data.filter(item => item.ESTADO === removeAccents(estado.toUpperCase()))
      if (matchEstado.length > 0) {
        validateAddress.push({
          response: true,
          messageEstado: "Estado válido por el catálogo del SAT."
        })
      } else {
        validateAddress.push({
          response: false,
          messageEstado: "Estado no válido por el catálogo del SAT."
        })
      }
      if (matchCP.length > 0) {
        validateAddress.push({
          response: true,
          messageCP: "Código postal válido por el catálogo del SAT."
        })
      } else {
        validateAddress.push({
          response: false,
          messageCP: "Código postal no válido por el catálogo del SAT."
        })
      }
      if (matchColonia.length > 0) {
        const { ID_COLONIA } = matchColonia[0]
        dataAddress.push({
          idColonia: ID_COLONIA,
        })
        validateAddress.push(
          {
            response: true,
            messageColonia: "Colonia válida por el catálogo del SAT."
          }
        )
      } else {
        validateAddress.push(
          {
            response: false,
            messageColonia: "Colonia no válida por el catálogo del SAT."
          }
        )
      }
      if (matchMunicipio.length > 0) {
        const { ID_MUNICIPIO, CVEESTADO } = matchMunicipio[0]
        dataAddress.push({
          idEstado: CVEESTADO,
          idMunicipio: ID_MUNICIPIO,
        })
        validateAddress.push(
          {
            response: true,
            messageMunicipio: "Municipio válido por el catálogo del SAT."
          }
        )
      } else {
        validateAddress.push(
          {
            response: false,
            messageMunicipio: "Municipio no válido por el catálogo del SAT."
          }
        )
      }
      return { validateAddress, dataAddress }
    }
  } catch (err) {
    console.log(err)
    return {
      response: false,
      message: "Ocurrió un error en el servidor, intente más tarde."
    }
  }
}


export const createPaymentIntent = async (amount) => {
  // convert to cents
  amount = Number(amount) * 100
  // fetch the backend url for a payment intent
  const paymentIntent = await fetch(`${BACKEND_URL}/payment-intent`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      details: {
        amount: amount,
      },
    }),
  }).catch((err) => {
    console.error(err)
    return err
  })
  const response = await paymentIntent.json()
  // * Pipedream
  return response.success
    ? { client_secret: response.client_secret }
    : { client_secret: null }
  // * Test => server 3000
  // return response.body.success
  //  ? { client_secret: response.body.client_secret }
  //  : { client_secret: null };
}

export const capitalizeData = (string) => {
  const capitalizeString = string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase()
  })
  return capitalizeString
}

export const sendEmail = async (
  data,
  senderDetails,
  receiverDetails,
) => {
  const {
    costo,
    createdAt,
    destino,
    fecha_entrega,
    origen,
    paquete,
    servicio,
    _id,
    ruta,
  } = data
  console.log("backend", data, senderDetails, receiverDetails)
  const costoFx = parseFloat(costo).toFixed(2)
  const iva = parseFloat(costoFx * 0.16).toFixed(2)
  const sendEmail = await fetch(`${URL_SENDINBLUE_PIPEDREAM}/send_email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      toEmail: await origen.email,
      subject: "Aerocharter",
      templateId: 3,
      params: {
        subTotal: parseFloat(costoFx - costoFx * 0.16).toFixed(2),
        total: costoFx,
        _id,
        weight: paquete?.peso,
        delivery_date: fecha_entrega,
        createdAt: createdAt?.split("T")[0],
        type_service: servicio,
        type_merchandise: capitalizeData(paquete?.descripcion),
        ruta: ruta,
        iva: iva,
        name_customer_ori: capitalizeData(origen?.nombre),
        phone_ori: origen?.telefono,
        email_ori: origen?.email,
        name_customer_des: capitalizeData(destino?.nombre),
        phone_des: destino?.telefono,
        email_des: destino?.email,
        streets_ori: capitalizeData(origen?.calle),
        ex_number_ori: origen?.exterior,
        int_number_ori: origen?.interior,
        colony_ori: capitalizeData(origen?.colonia),
        city_ori: capitalizeData(origen?.ciudad),
        municipality_ori: capitalizeData(origen?.municipio),
        postal_code_ori: origen?.cp,
        state_origin_ori: origen?.estado,
        type_address_ori: senderDetails?.place_type,
        reference_streets_ori: senderDetails?.reference_street,
        streets_des: capitalizeData(destino?.calle),
        ex_number_des: destino?.exterior,
        int_number_des: destino?.interior,
        colony_des: capitalizeData(destino?.colonia),
        city_des: capitalizeData(destino?.ciudad),
        municipality_des: capitalizeData(destino?.municipio),
        postal_code_des: destino?.cp,
        state_origin_des: destino?.estado,
        type_address_des: receiverDetails?.place_type,
        reference_streets_des: receiverDetails?.reference_street,
      },
      fromEmail: "dev@ildiko.tech",
    }),
  }).catch((err) => {
    console.log(err)
    return err
  })
  sessionStorage.setItem('saveSendEmail', JSON.stringify({ confirm: true }))
  return await sendEmail
}

const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

export const createInvoice = async (
  {
    email,
    exterior,
    factura,
    first_name,
    interior,
    last_name,
    phone,
    postalcode,
    regimen,
    rfc,
    street,
    estado
  },
  costo,
  states,
  dataAddress = []
) => {
  console.log(estado)
  const { origen, destino } = states
  const codeCFDI = factura?.split("-")[0].trim()
  const codeRegimen = parseInt(regimen?.split("-")[0].trim())
  const regimenValidos = cfdiRegimen.map((item) =>
    item[codeCFDI].includes(codeRegimen)
  )
  if (regimenValidos[0]) {
    // TODO: Pendiente buscar por codigo la colonia y el estado
    try {
      // const sendData = await fetch(`${BACKEND_URL}/facturacion`, {
      const sendData = await fetch(API_FACTURACION, {
        method: "POST",
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: "PH",
          password: "12345",
          cliente: {
            nombre: `${first_name} ${last_name}`,
            correo: email,
            tel: phone,
            rfc: rfc,
            direccion: {
              calle: street,
              n_ext: exterior,
              n_int: interior,
              colonia: dataAddress[0]?.idColonia,
              municipio: dataAddress[1]?.idMunicipio,
              estado: dataAddress[1]?.idEstado,
              pais: "Mexico",
              cp: postalcode,
            },
          },
          factura: {
            regimen: codeRegimen,
            moneda: "MXN",
            forma: "03",
            metodo: "PUE",
            cdfi: codeCFDI,
            iva: 16,
            unidad: "XGR",
            cantidad: 40,
            precio: costo,
            descuento: 0,
            origen: removeAccents(origen) === 'Ciudad de Mexico' ? 'Mexico' : removeAccents(origen),
            destino: removeAccents(destino) === 'Ciudad de Mexico' ? 'Mexico' : removeAccents(destino),
            objetoimpuesto: "01",
            concepto: "GASTOS POR COMPROBAR",
          },
        }),
      }).then(async (res) => {
        const { status } = res
        if (status === 400) {
          const { code, message } = await res.json()
          if (code === 400) {
            return {
              title: "Error!",
              message,
              type: "error",
            }
          }
        } else if (status === 404) {
          const { message, data } = await res.json()
          return {
            title: message,
            message: data,
            type: "error",
          }
        } else if (status === 200) {
          const blobData = await res.blob()
          return blobData
        }
      })
      return sendData
    } catch (err) {
      return {
        title: "Error",
        message: "Error de conexión con el servidor, intente más tarde",
        type: "error",
      }
    }
  } else {
    return {
      title: "Error!",
      message: "Combinación de Uso de Factura y Régimen Fiscal no válido",
      type: "error",
    }
  }
}
