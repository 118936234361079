import { isEmptyObject } from "components/helpers";
import * as Config from "config.json";
export const storeQuoteParams = (quoteParams) =>
  setData("quoteParams", quoteParams);
export const getQuoteParams = () => getData("quoteParams");

export const storeSenderDetails = (senderDetails) =>
  setData("senderDetails", senderDetails);
export const getSenderDetails = () => getData("senderDetails");

export const storeReceiverDetails = (receiverDetails) =>
  setData("receiverDetails", receiverDetails);
export const getReceiverDetails = () => getData("receiverDetails");

export const storeGuide = (guide) => setData("guide", guide);
export const getGuide = () => getData("guide");

export const storeRoutes = (routes) => setData("routes", routes);
export const getRoutes = () => getData("routes");

// * Creamos el setData de la facturación
// export const storeFacturación = (dataFacturacion) => setData("")

export const checkStaleData = () => {
  let lastAppInfo = localStorage.getItem("appInfo") || "{}";
  lastAppInfo = JSON.parse(lastAppInfo);
  if (
    isEmptyObject(lastAppInfo) ||
    lastAppInfo.version !== Config.APP_VERSION
  ) {
    // different version, limpia el local storage una vez que se manden los datos y estos sean validos
    // console.log("data is stale, cleaning the store")
    localStorage.removeItem("quoteParams");
    localStorage.removeItem("senderDetails");
    localStorage.removeItem("receiverDetails");
    localStorage.removeItem("guide");
    sessionStorage.removeItem("paymentData");
    localStorage.removeItem("routes");
    localStorage.removeItem("appInfo");
    sessionStorage.removeItem("saveSendEmail");

  }
  // sessionStorage.removeItem("paymentData");
  localStorage.setItem(
    "appInfo",
    JSON.stringify({ version: Config.APP_VERSION })
  );
};

// 60 s * 60 m * 24 h * n d * (1000 ms)
// const expirationTime = ((60 * 60 * 24) * 5) * 1000; // 5 days
// 60 s * 60m * n h * 1000ms
const expirationTime = 60 * 60 * 1 * 1000; // 1 hr
// Define los datos que esta enviando el cliente
const setData = (keyName, data) => {
  // console.log("setData", keyName, data)
  let storedData = localStorage.getItem(keyName);
  let parsedData = storedData ? JSON.parse(storedData) : {};
  // merge existing data and new data
  const creationTime = new Date().getTime();
  parsedData = { ...parsedData, ...data, creationTime: creationTime };
  localStorage.setItem(keyName, JSON.stringify(parsedData));
};
const getData = (keyName) => {
  let data = localStorage.getItem(keyName);
  data = data ? JSON.parse(data) : null;
  const actualTime = new Date().getTime();
  if (
    data &&
    data.creationTime &&
    data.creationTime + expirationTime <= actualTime
  ) {
    localStorage.removeItem(keyName);
    data = null;
  }
  return data;
};
