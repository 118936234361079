import React from 'react'
import './src/styles/global.css'
import { STRIPE_DETAILS } from './src/config.json'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { StoreProvider } from './src/app/store/store'
import { initialState, applicationReducer } from './src/app/store/applicationReducer'

const stripePromise = loadStripe(
    STRIPE_DETAILS.public_key,
    {
        locale: 'es-419',
    })
const stripeOptions = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css?family=Inter:400,600',
        },
    ]
    // grab this from server only if we need to use payment Element
    // clientSecret: STRIPE_DETAILS.client_secret,
}
export const wrapRootElement = ({ element }) => {
    return (
        <Elements stripe={stripePromise} options={stripeOptions}>
            <StoreProvider initialState={initialState} reducer={applicationReducer}>
                {element}
            </StoreProvider>
        </Elements>
    )
}