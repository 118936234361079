exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-privacidad-js": () => import("./../../../src/pages/aviso-privacidad.js" /* webpackChunkName: "component---src-pages-aviso-privacidad-js" */),
  "component---src-pages-cotizar-envio-js": () => import("./../../../src/pages/cotizar-envio.js" /* webpackChunkName: "component---src-pages-cotizar-envio-js" */),
  "component---src-pages-detalles-de-la-cotizacion-js": () => import("./../../../src/pages/detalles-de-la-cotizacion.js" /* webpackChunkName: "component---src-pages-detalles-de-la-cotizacion-js" */),
  "component---src-pages-etiqueta-js": () => import("./../../../src/pages/etiqueta.js" /* webpackChunkName: "component---src-pages-etiqueta-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pago-completado-js": () => import("./../../../src/pages/pago-completado.js" /* webpackChunkName: "component---src-pages-pago-completado-js" */),
  "component---src-pages-pago-rechazado-js": () => import("./../../../src/pages/pago-rechazado.js" /* webpackChunkName: "component---src-pages-pago-rechazado-js" */),
  "component---src-pages-rastreo-js": () => import("./../../../src/pages/rastreo.js" /* webpackChunkName: "component---src-pages-rastreo-js" */),
  "component---src-pages-realizar-envio-js": () => import("./../../../src/pages/realizar-envio.js" /* webpackChunkName: "component---src-pages-realizar-envio-js" */),
  "component---src-pages-realizar-factura-js": () => import("./../../../src/pages/realizar-factura.js" /* webpackChunkName: "component---src-pages-realizar-factura-js" */),
  "component---src-pages-realizar-pago-js": () => import("./../../../src/pages/realizar_pago.js" /* webpackChunkName: "component---src-pages-realizar-pago-js" */),
  "component---src-pages-resumen-del-envio-js": () => import("./../../../src/pages/resumen-del-envio.js" /* webpackChunkName: "component---src-pages-resumen-del-envio-js" */),
  "component---src-pages-terminos-y-condiciones-js": () => import("./../../../src/pages/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-js" */)
}

