export const PIPEDREAM_ROOT_URL = "https://enytl2sehbbmk9q.m.pipedream.net";
export const API_ROOT_URL_GRAPH = "https://aerocharter-api.herokuapp.com/api";
export const API_ROOT_URL = "https://aerocontrol-api.herokuapp.com/api/v1";
export const COPOMEX_ROOT_URL = "https://api.copomex.com/query/";
export const TEST_BACKEND_URL = "http://localhost:3000";
export const API_FACTURACION = "https://front.awesome-cargo.com/api/umilla";
export const API_AEROCHARTER = "https://api.aerocontrol.com.mx/v1";

// ! Tipos de servicios
export const SERVICES_TYPE = [
  { name: "Puerta a Puerta", id: "PP" },
  { name: "Puerta a Aeropuerto", id: "PA" },
  { name: "Aeropuerto a Puerta", id: "AP" },
];

// * Tipos de mensajes
export const COTIZANDO = {
  title: "Cotizando...",
  message: "Estamos procesando tu solicitud.",
  type: "info",
};


// * Tipos de mensajes: VALIDACIÓN DE CÓDIGOS POSTALES
export const ACCEPTCP = {
  title: "Validando códigos postales...",
  message: "Estamos validando los códigos postales de tu solicitud.",
  type: "info",
};

export const NOACCEPTCP = {
  title: "Realiza una nueva cotización",
  message: "Los códigos postales no estan disponibles para aerocharter",
  type: "error",
};

export const ACCEPTMESS = {
  title: "Códigos Postales Validos",
  message: "Los códigos postales son disponibles para aerocharter",
  type: "success",
};