import { Graph } from "jsnetworkx/node/classes";

const createNode = (id, name, _in, _out) => {
  return [
    id,
    {
      id: id,
      name: name,
      in: _in,
      out: _out
    }
  ];
};

const createEdge = (origin, destination, data) => {
  return [origin, destination, data];
};

export const LocationsGraph = new Graph();

// initialize the graph
// // let nodeItems = nodes.map(node => createNode(node.id, node.name))
// // let edgeItems = edges.map(edge => createEdge(edge.cedis_origin, edge.cedis_destination, edge))
// // console.log( nodeItems[0].toString() );
// LocationsGraph.addNodesFrom(nodeItems);
// LocationsGraph.addEdgesFrom(edgeItems);

// graph.add
// console.log(
//     LocationsGraph,
//     graph.nodes()
// )

/**
 *
 *
 * @param {[Object]} routes
 * @param {String} routes[].["A/T DESTINO"]
 * @param {String} routes[].["A/T ORIGEN"]
 * @param {String} routes[].estado_origen
 * @param {String} routes[].estado_destino
 * @param {String} routes[].cedis_primera_milla
 * @param {String} routes[].cedis_ultima_milla
 * @param {String} routes[].cedis_conexion_viv
 * @param {String} routes[].paquetera_primera_milla
 * @param {String} routes[].paquetera_ultima_milla
 */
export const fillGraphData = (routes) => {
  // nodes set

  let nodes = new Map();
  let edges = [];

  let posible_origins = new Map();
  let posible_destinations = new Map();
  routes.forEach((route) => {
    let nodeOrigin = {
      id: route.estado_origen,
      name: route.estado_origen,
      in: false,
      out: false
    };
    posible_origins.set(nodeOrigin.id, nodeOrigin);

    let nodeDestination = {
      id: route.estado_destino,
      name: route.estado_destino,
      in: false,
      out: false
    };
    posible_destinations.set(nodeDestination.id, nodeDestination);

    nodes.set(nodeOrigin.id, nodeOrigin);
    nodes.set(nodeDestination.id, nodeDestination);

    // add edges
    edges.push({
      origin: nodeOrigin.id,
      destination: nodeDestination.id,
      data: {
        origin_id: nodeOrigin.id,
        destination_id: nodeDestination.id,
        origin_name: nodeOrigin.name,
        destination_name: nodeDestination.name,
        viv: route.cedis_conexion_viv,
        paq_first_mile: route.paquetera_primera_milla,
        paq_last_mile: route.paquetera_ultima_milla
      }
    });
  });

  let nodeItems = Array.from(nodes.values()).map((node) =>
    createNode(node.id, node.name, node.in, node.out)
  );
  let edgeItems = edges.map((edge) =>
    createEdge(edge.origin, edge.destination, edge.data)
  );
  // console.log(nodeItems)
  nodeItems = nodeItems.map((node) => {
    // console.log(node[0], posible_destinations.get(node[0]))
    node[1].in = posible_destinations.has(node[0]) ? true : false;
    node[1].out = posible_origins.has(node[0]) ? true : false;
    return node;
  });
  // console.log("items marked",nodeItems)
  LocationsGraph.addNodesFrom(nodeItems);
  LocationsGraph.addEdgesFrom(edgeItems);
  // console.log("nodes",nodes, edges);
  window.graph = LocationsGraph;
};
