import React, { useCallback, useEffect, useReducer, useState } from "react"
import eventBus from "../services/eventBus"
import {
  applicationBootsrap,
  applicationMiddlewareReducer,
  getAuthToken,
  getLastGuideFromLocalStorage,
  getLocationsGraph,
  getQuoteParamsFromLocalStorage,
  getReceiverDetailsFromLocalStorage,
  getSenderDetailsFromLocalStorage,
} from "./applicationReducer"

const Store = React.createContext()

Store.displayName = "Store"

export const useStore = () => React.useContext(Store)

export const StoreProvider = ({ children, initialState, reducer }) => {
  // // Se declara el estado global que se va a querer cambiar
  const [globalState, dispatch] = useReducer(reducer, initialState)

  // --eslint-disable-next-line react-hooks/exhaustive-deps
  //* Se ejecutara cada que cambien sus dependencias
  const middleWareDispatcher = useCallback(
    applicationMiddlewareReducer(dispatch, eventBus),
    []
  )

  // const middleWareDispatcher = applicationMiddlewareReducer(dispatch)

  useEffect(() => {
    // console.log("store mounted", "APP_VERSION");
    middleWareDispatcher(applicationBootsrap())

    middleWareDispatcher(getAuthToken())

    // get previously saved params from localStorage
    middleWareDispatcher(getSenderDetailsFromLocalStorage())
    middleWareDispatcher(getReceiverDetailsFromLocalStorage())
    middleWareDispatcher(getQuoteParamsFromLocalStorage())
    middleWareDispatcher(getLastGuideFromLocalStorage())
    // load graph data
    middleWareDispatcher(getLocationsGraph())
  }, [middleWareDispatcher])

  // inject the middleware dispatcher
  return (
    <Store.Provider value={[globalState, middleWareDispatcher, eventBus]}>
      {children}
    </Store.Provider>
  )
}
